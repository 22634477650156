import axios from 'axios';

const BASE_URL = 'https://app.loonw-space.com:5000/api';

export const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  withCredentials: true, // Важный флаг для Safari
});

// Логирование запроса
api.interceptors.request.use(
  (config) => {
    console.log('[Request]', {
      method: config.method,
      url: config.url,
      headers: config.headers,
      data: config.data,
      params: config.params,
    });
    return config;
  },
  (error) => {
    console.error('[Request Error]', error);
    return Promise.reject(error);
  }
);

// Логирование ответа
api.interceptors.response.use(
  (response) => {
    console.log('[Response]', {
      status: response.status,
      statusText: response.statusText,
      url: response.config.url,
      headers: response.headers,
      data: response.data,
    });
    return response;
  },
  (error) => {
    console.error('[Response Error]', {
      message: error.message,
      config: error.config,
      response: error.response ? {
        status: error.response.status,
        statusText: error.response.statusText,
        headers: error.response.headers,
        data: error.response.data,
      } : 'No Response',
      request: error.request,
    });
    return Promise.reject(error);
  }
);

export default api;
