import React, { useEffect } from 'react'
import AddResponseWrapper from '../components/AddResponse/AddResponseWrapper'
import { useNavigate, useParams } from 'react-router-dom';
import WebApp from '@twa-dev/sdk';

function AddResponse() {
  const { orderId } = useParams();

    const navigate = useNavigate();
  
    useEffect(() => {
      const backButton = WebApp.BackButton;
  
      backButton.show();
      backButton.onClick(() => {
          console.log('[TWA BackButton] Возврат назад');
          navigate('/menu'); // Возвращаемся на страницу чатов
      });
  
      return () => {
          backButton.hide();
          backButton.offClick();
      };
    }, [navigate]);

  return (
    <div className='AddResponse'>
        <AddResponseWrapper orderId={orderId} />
    </div>
  )
}

export default AddResponse