import React, { useEffect } from 'react';
import Button from '../components/ui-kit/Button';
import PortfolioBlock from '../components/Portfolio/PortfolioBlock';
import usePortfolioStore from '../store/portfolioStore';
import WebApp from '@twa-dev/sdk';
import { useNavigate } from 'react-router-dom';

function Portfolio() {
  const telegramId = WebApp.initDataUnsafe?.user.id;
  const { portfolio, isLoading, error, fetchPortfolioByTelegramId } = usePortfolioStore();

  useEffect(() => {
    if (telegramId) {
      fetchPortfolioByTelegramId(telegramId);
    }
  }, [telegramId, fetchPortfolioByTelegramId]);

  const navigate = useNavigate();

  useEffect(() => {
    const backButton = WebApp.BackButton;

    backButton.show();
    backButton.onClick(() => {
        console.log('[TWA BackButton] Возврат назад');
        navigate('/menu');
    });

    return () => {
        backButton.hide();
        backButton.offClick();
    };
  }, [navigate]);

  return (
    <div className='Portfolio'>
      <div className="portfolio__container">
        <div className="titlePage" style={{ textAlign: 'center' }}>
          Мое портфолио:
        </div>

        <Button text='Добавить работу' href="/addJobPortfolio" />

        {isLoading && <p>Загрузка...</p>}

        {error && <p style={{ color: 'red' }}>{error}</p>}

        {portfolio.length === 0 ? (
          <p>Портфолио пусто.</p>
        ) : (
          portfolio.map((item, index) => (
            <PortfolioBlock
              key={index}
              img={`https://api.loonw-space.com:5000${item.file}`} 
              info={item.description} 
              widthLeft="75%" 
              showButton={true}
              id={item._id}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default Portfolio;
