import React from 'react';

function ContextMenu({ menuVisible, menuRef, buttons }) {
  if (!menuVisible) return null;

  return (
    <div className="contextMenu" style={{ top: 30 }} ref={menuRef}>
      {buttons.map((button, index) => (
        <a key={index} className="contextButton" href={button.href} onClick={button.onClick}>
          {button.label}
        </a>
      ))}
    </div>
  );
}

export default ContextMenu;
