import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useChatStore from '../store/chatStore';
import useUserStore from '../store/userStore';
import WebApp from '@twa-dev/sdk';
import DefaultAvatar from '../assets/defaultAvatar.png';
import { FaPaperPlane } from 'react-icons/fa';

function Chat() {
    const { chatId } = useParams();
    const navigate = useNavigate();
    const { currentChat, messages, sendMessage, fetchChatById, initWebSocket, checkNewMessages } = useChatStore();
    const { otherUser, setOtherUser } = useUserStore();
    const [inputText, setInputText] = useState('');
    const currentUser = WebApp.initDataUnsafe?.user?.id;

    const messagesEndRef = useRef(null);

    console.log('Текущий пользователь (currentUser):', currentUser);

    // Инициализация WebSocket и загрузка чата
    useEffect(() => {
        if (!chatId) return;

        console.log('[useEffect] Инициализация WebSocket и загрузка чата');
        initWebSocket();
        fetchChatById(chatId);

        const interval = setInterval(() => {
            console.log('[Interval] Проверка новых сообщений');
            checkNewMessages();
        }, 5000);

        return () => clearInterval(interval);
    }, [chatId, initWebSocket, fetchChatById, checkNewMessages]);

    // Определение оппонента чата
    useEffect(() => {
        if (!currentChat?.participants || !currentUser) return;

        const opponent = currentChat.participants.find(
            (p) => String(p.telegramId) !== String(currentUser)
        );

        if (opponent) {
            setOtherUser({
                _id: opponent._id,
                nickname: opponent.nickname,
                avatar: opponent.avatar,
            });
        }
    }, [currentChat, currentUser, setOtherUser]);

    // Скроллим вниз при изменении сообщений
    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    // Обработчик отправки сообщений
    const handleSendMessage = () => {
        if (inputText.trim()) {
            sendMessage(chatId, inputText);
            setInputText('');
        }
    };

    // Активация кнопки назад из Telegram Web App SDK
    useEffect(() => {
        const backButton = WebApp.BackButton;

        backButton.show();
        backButton.onClick(() => {
            console.log('[TWA BackButton] Возврат назад');
            navigate('/chats'); // Возвращаемся на страницу чатов
        });

        return () => {
            backButton.hide();
            backButton.offClick();
        };
    }, [navigate]);

    return (
        <div className="Chat">
            {/* Шапка с информацией об оппоненте */}
            <div className="headerChat">
                {otherUser ? (
                    <>
                        <img
                            src={otherUser.avatar ? `https://api.loonw-space.com:5000${otherUser.avatar}` : DefaultAvatar}
                            alt="Avatar"
                            className="avatar"
                        />
                        <span className="nickname">{otherUser.nickname || 'Собеседник'}</span>
                    </>
                ) : (
                    <span>Загрузка...</span>
                )}
            </div>

            {/* Сообщения */}
            <div className="messages">
                {messages.map((msg, index) => (
                    <div
                        key={index}
                        className={`message ${
                            String(msg.senderTelegramId) === String(currentUser) ? 'right' : 'left'
                        }`}
                    >
                        <div className="message-text">{msg.text}</div>
                        <div className="message-time">
                            {new Date(msg.createdAt).toLocaleString('ru-RU', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                            })}
                        </div>
                    </div>
                ))}
                {/* Реф для прокрутки вниз */}
                <div ref={messagesEndRef} />
            </div>

            {/* Поле ввода сообщения */}
            <div className="addMessage">
                <input
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                    placeholder="Введите сообщение"
                />
                <button onClick={handleSendMessage}>
                    <div className="icon">
                        <FaPaperPlane />
                    </div>
                </button>
            </div>
        </div>
    );
}

export default Chat;
