import React, { useState, useEffect, useRef } from 'react';
import DefaultAvatar from '../../assets/defaultAvatar.png';
import ContextMenu from '../ContextMenu';

function ProfileHeader({ user }) {
  const [menuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef(null);

  const handleDotsClick = (event) => {
    event.stopPropagation();
    setMenuVisible((prevState) => !prevState);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const buttons = [
    {
      label: 'Поделиться',
      href: `https://t.me/share/url?url=https://t.me/loonwspace_bot/lspace?startapp=${user.telegramId}`,
    },
    {
      label: 'Пожаловаться',
      href: 'https://t.me/loonwsupport_bot'
    },
  ];

  return (
    <div className="MenuUser">
      <div className="MenuUser__container">
        <div className="avatar">
          <img 
            src={user.avatar ? `https://api.loonw-space.com:5000${user.avatar}` : DefaultAvatar} 
            alt="Avatar" 
          />
        </div>

        <div className="info">
          <div className="fullName">
            <div className="name">{user.nickname || 'Без имени'}</div>

            <div className="dots" onClick={handleDotsClick}>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          </div>

          <div className="specialnost">
            {user.specialnost || 'Специальность не указана'}
          </div>
        </div>

        <ContextMenu menuVisible={menuVisible} menuRef={menuRef} buttons={buttons} />
      </div>
    </div>
  );
}

export default ProfileHeader;
