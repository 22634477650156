import React from 'react'
import EmptyImage from '../assets/Logo_Orig.png'

function EmptyData() {
  return (
    <div className='EmptyData'>
        <div className="img">
            <img src={EmptyImage} />
        </div>

        <div className="text">
            Тут пока ничего нету :(
        </div>
    </div>
  )
}

export default EmptyData