import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ProfileHeader from '../components/Profile/ProfileHeader';
import useUserStore from '../store/userStore';
import ReviewProfile from '../components/Profile/ReviewProfile';
import DescProfile from '../components/Profile/DescProfile';
import ReviewsProfile from '../components/Profile/ReviewsProfile';
import PortfolioProfile from '../components/Profile/PortfolioProfile';

function Profile() {
  const { telegramId } = useParams();
  const { fetchOtherUserInfo, otherUser } = useUserStore();

  useEffect(() => {
    if (telegramId) {
      fetchOtherUserInfo(telegramId);
    }
  }, [telegramId, fetchOtherUserInfo]);

  if (!otherUser) {
    return <div>Загрузка...</div>;
  }

  return (
    <div className="Profile">
      <ProfileHeader user={otherUser} />

      <div className="Profile__container">
        <ReviewProfile />
        <DescProfile description={otherUser.description} />
        <ReviewsProfile />
        {otherUser.portfolio.length !== 0 && <PortfolioProfile portfolio={otherUser.portfolio} />}
      </div>
    </div>
  );
}

export default Profile;
