import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { api } from '../api/api';
import { showNotification } from '../ToastNotification';
import WebApp from '@twa-dev/sdk';

const usePortfolioStore = create(
  devtools((set, get) => ({
    portfolio: [],
    isLoading: false,
    error: null,
    successMessage: null,

    fetchPortfolioByTelegramId: async (telegramId) => {
      set({ isLoading: true, error: null });
      try {
        const response = await api.get(`/portfolio/${telegramId}`);
        
        if (Array.isArray(response.data.portfolio)) {
          set({ portfolio: response.data.portfolio });
        } else {
          set({ portfolio: [] });
        }

      } catch (error) {
        showNotification('Ошибка при загрузке портфолио', 'error');
        set({ error: 'Ошибка при загрузке портфолио' });
      } finally {
        set({ isLoading: false });
      }
    },

    addWorkToPortfolio: async (telegramId, file, description) => {
      if (get().isLoading) return;

      set({ isLoading: true });
      try {
        const formData = new FormData();
        formData.append('work', file);
        formData.append('description', description);

        const response = await api.post(`/portfolio/${telegramId}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        set({ portfolio: [...get().portfolio, response.data.file] });
        showNotification('Работа успешно добавлена в портфолио!', 'success');
      } catch (error) {
        showNotification('Не удалось добавить работу в портфолио', 'error');
        set({ error: 'Ошибка при добавлении работы в портфолио' });
      } finally {
        set({ isLoading: false });
      }
    },

    deleteWorkFromPortfolio: async (workId) => {
        const telegramId = WebApp.initDataUnsafe?.user.id
        set({ isLoading: true });
        try {
          const response = await api.delete(`/portfolio/${telegramId}/${workId}`);
          set({ portfolio: response.data.portfolio });
          showNotification('Работа успешно удалена из портфолио!', 'success');
        } catch (error) {
          showNotification('Не удалось удалить работу из портфолио', 'error');
          set({ error: 'Ошибка при удалении работы из портфолио' });
        } finally {
          set({ isLoading: false });
        }
      },
  }))
);

export default usePortfolioStore;
