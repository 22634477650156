import React, { useEffect, useState } from 'react';
import { BsSearch } from "react-icons/bs";
import useUserStore from '../../store/userStore';
import Button from '../ui-kit/Button';

function HomeOne() {
  const { user, fetchUserInfo } = useUserStore();
  const [role, setRole] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      await fetchUserInfo();
      setRole(user?.role);
    };

    fetchUserData();
  }, [fetchUserInfo, user]);

  if (role === null) {
    return <div className="HomeOne__loading">Загрузка...</div>;
  }

  return (
    <div className='HomeOne'>
      <div className="title">
        {role === 'Заказчик' ? (
          <>
            Какая услуга вас <br /> интересует?
          </>
        ) : (
          <>Начните работать <br /> прямо сейчас!</>
        )}
      </div>

      {role === 'Заказчик' ? (
        <>
{/*         <div className="searchInput">
          <div className="icon">
            <BsSearch />
          </div>
          <input type="text" placeholder='Поиск...' />
        </div> */}
        <div></div>
        </>
      ) : (
        <>
        {/* <Button className="button" text='Фильтры' /> */}
        <div></div>
        </>
      )}
    </div>
  );
}

export default HomeOne;
