import React, { useState, useEffect } from 'react';
import Input from '../components/ui-kit/Input';
import Textarea from '../components/ui-kit/Textarea';
import Button from '../components/ui-kit/Button';
import { BsPaperclip } from 'react-icons/bs';
import useUserStore from '../store/userStore';
import WebApp from '@twa-dev/sdk';
import { useNavigate } from 'react-router-dom';

function EditProfile() {
  const { user, updateUserInfo, fetchUserInfo } = useUserStore();
  const [nickname, setNickname] = useState('');
  const [specialnost, setSpecialnost] = useState('');
  const [description, setDescription] = useState('');
  const [avatar, setAvatar] = useState(null);
  const [avatarStatus, setAvatarStatus] = useState('Изменить аватар'); // Статус загрузки аватара

  const navigate = useNavigate();

  useEffect(() => {
    fetchUserInfo(); // Загружаем данные пользователя при монтировании
  }, [fetchUserInfo]);

  useEffect(() => {
    if (user) {
      setNickname(user.nickname || '');
      setSpecialnost(user.specialnost || '');
      setDescription(user.description || '');
    }
  }, [user]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setAvatar(file);
      setAvatarStatus('Загружено'); // Обновляем статус при выборе файла
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updates = { nickname, specialnost, description };
    updateUserInfo(updates, avatar);
  };

  useEffect(() => {
    const backButton = WebApp.BackButton;

    backButton.show();
    backButton.onClick(() => {
        console.log('[TWA BackButton] Возврат назад');
        navigate('/menu'); // Возвращаемся на страницу чатов
    });

    return () => {
        backButton.hide();
        backButton.offClick();
    };
  }, [navigate]);

  return (
    <div className="EditProfile">
      <div className="EditProfile__container">
        <div className="titlePage">Редактировать профиль</div>

        <div className="inputWrapper">
          <Input
            label="Ваше имя"
            text="Отображаемое имя на бирже"
            value={nickname}
            onChange={setNickname}
          />
        </div>

        <div className="inputWrapper">
          <Input
            label="Специальность"
            text="Ваша роль на бирже"
            value={specialnost}
            onChange={setSpecialnost}
          />
        </div>

        <div className="inputWrapper">
          <Textarea
            label="Описание"
            text="Расскажите про себя"
            value={description}
            onChange={setDescription}
          />
        </div>

        <div className="inputWrapper fileWrapper">
          <label htmlFor="avatarInput" className="fileLabel">
            <BsPaperclip />
            <span>{avatarStatus}</span> {/* Отображение статуса загрузки */}
          </label>
          <input
            id="avatarInput"
            type="file"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
        </div>

        <Button text="Сохранить" onClick={handleSubmit} />
      </div>
    </div>
  );
}

export default EditProfile;
