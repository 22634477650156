import React from 'react';
import MenuButton from './MenuButton';
import {
  BsPerson,
  BsGrid,
  BsCurrencyDollar,
  BsHeart,
  BsFolderPlus,
  BsPersonGear,
  BsFolder,
  BsCalendar3,
  BsClipboardHeart
} from 'react-icons/bs';
import useUserStore from '../../store/userStore';

function MenuButtons() {
  const user = useUserStore((state) => state.user);

  if (!user) {
    return <div>Загрузка...</div>;
  }

  const buttons = [
    { icon: <BsPerson />, text: 'Мой профиль', to: `/profile/${user.telegramId}` },
    user.role === 'Заказчик' && {
      icon: <BsFolderPlus />,
      text: 'Добавить заказ на биржу',
      to: '/addOrder',
    },
    user.role === 'Исполнитель' && {
      icon: <BsFolderPlus />,
      text: 'Добавить объявление',
      to: '/addAdvertising',
    },
    user.role === 'Исполнитель' && {
      icon: <BsFolder />,
      text: 'Мои объявления',
      to: '/myAdvertising',
    },
/*     user.role === 'Исполнитель' && {
      icon: <BsClipboardHeart />,
      text: 'Мои отклики',
      to: '/myResponses',
    }, */
    user.role === 'Исполнитель' && {
      icon: <BsCalendar3 />,
      text: 'Портфолио',
      to: '/portfolio',
    },
    { icon: <BsGrid />, text: 'Задания', to: '/tasks' },
    { icon: <BsCurrencyDollar />, text: 'Баланс', to: '/balance' },
    /* { icon: <BsHeart />, text: 'Избранное', to: '/favorites' }, */
    { icon: <BsPersonGear />, text: 'Тех. поддержка', to: 'https://t.me/loonwsupport_bot' },
  ].filter(Boolean);

  return (
    <div className="MenuButtons">
      <div className="MenuButtons__container">
        <div className="title">МЕНЮ:</div>

        <div className="buttonsContainer">
          {buttons.map((button, index) => (
            <MenuButton
              key={index}
              icon={button.icon}
              text={button.text}
              to={button.to}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default MenuButtons;
