import React, { useEffect } from 'react';
import TaskBlock from '../components/Tasks/TaskBlock';
import useTaskStore from '../store/taskStore';

function Tasks() {
  const { tasks, fetchTasks, isLoading } = useTaskStore();

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  return (
    <div className="Tasks">
      <div className="Tasks__container">
        <div className="titlePage">Задания</div>
        <div className="text">
          Выполняйте простые задания на платформе и получайте <span>$LS</span> поинты за свою активность.
        </div>

        {isLoading ? (
          <div>Загрузка...</div>
        ) : (
          tasks.map((task) => (
            <TaskBlock
              key={task._id}
              title={task.title}
              reward={task.reward}
              taskId={task._id}
              goTo={task.goTo} // Передаём ссылку
            />
          ))
        )}
      </div>
    </div>
  );
}

export default Tasks;
