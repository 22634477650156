import React, { useState, useEffect } from 'react';
import { BsBoxSeam } from "react-icons/bs";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Button from '../ui-kit/Button';
import useUserStore from '../../store/userStore';
import DefaultAvatar from '../../assets/defaultAvatar.png';

function RefferalsWrapper() {
  const [copied, setCopied] = useState(false);
  const [invitedUsers, setInvitedUsers] = useState([]);
  
  const { user, fetchUserInfo, fetchOtherUserInfo } = useUserStore();

  useEffect(() => {
    fetchUserInfo();
  }, [fetchUserInfo]);

  useEffect(() => {
    const loadInvitedUsers = async () => {
      if (user?.referredUsers?.length) {
        const usersData = await Promise.all(
          user.referredUsers.map(async (telegramId) => {
            await fetchOtherUserInfo(telegramId);
            return useUserStore.getState().otherUser;
          })
        );
        setInvitedUsers(usersData);
      }
    };

    if (user) {
      loadInvitedUsers();
    }
  }, [user, fetchOtherUserInfo]);

  return (
    <div className='RefferalsWrapper'>
      <div className="RefferalsWrapper__container">
        <div className="titlePage">
          Реферальная система
        </div>

        <div className="infoBlock">
          <div className="left">
            <BsBoxSeam />
          </div>
          <div className="right">
            <div className="title">Получите:</div>
            <div className="text">150 $LS за приведенного друга</div>
          </div>
        </div>

        {user && user.referralLink ? (
          <CopyToClipboard text={user.referralLink} onCopy={() => setCopied(true)}>
            <Button text={copied ? "Скопировано!" : "Копировать ссылку"} />
          </CopyToClipboard>
        ) : (
          <div>Загрузка...</div>
        )}

        <div className="invitedUsers">
          <div className='text'>Приглашённые пользователи:</div>
          {invitedUsers.length > 0 ? (
            invitedUsers.map((invitedUser) => (
              <div key={invitedUser.telegramId} className="invitedUser">
                  <div className="left">
                  <img 
                    src={user.avatar 
                      ? `https://api.loonw-space.com:5000${user.avatar}` 
                      : DefaultAvatar} 
                    alt="Avatar" 
                  />
                  <div className="nickname">{invitedUser.nickname || 'Без имени'}</div>
                </div>

                <div className="right">
                  +150 $LS
                </div>
              </div>
            ))
          ) : (
            <div>Нет приглашённых пользователей</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RefferalsWrapper;
