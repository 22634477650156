import React, { useState, useEffect } from 'react';
import Input from '../ui-kit/Input';
import Select from '../ui-kit/Select';
import Textarea from '../ui-kit/Textarea';
import Button from '../ui-kit/Button';
import { BsFileRichtext, BsFileEarmarkText, BsPaperclip } from 'react-icons/bs';
import useOrderStore from '../../store/orderStore';
import useCategoryStore from '../../store/categoriesStore';
import WebApp from '@twa-dev/sdk';

function AddOrderWrapper() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [category, setCategory] = useState('');
  const [subcategory, setSubcategory] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('TON');
  const [files, setFiles] = useState([]);
  const [telegramId, setTelegramId] = useState(null);

  const { createOrder, loading } = useOrderStore();
  const { categories, subcategories, fetchCategories, fetchSubcategoriesByCategoryId } = useCategoryStore();

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  useEffect(() => {
    if (category) {
      fetchSubcategoriesByCategoryId(category);
    }
  }, [category, fetchSubcategoriesByCategoryId]);

  useEffect(() => {
    const user = WebApp.initDataUnsafe?.user;
    if (user && user.id) {
      setTelegramId(user.id);
    } else {
      alert('Ошибка: не удалось получить идентификатор Telegram.');
    }
  }, []);

  // Устанавливаем первую категорию по умолчанию
  useEffect(() => {
    if (categories.length > 0 && !category) {
      setCategory(categories[0]._id);
    }
  }, [categories]);

  // Устанавливаем первую подкатегорию по умолчанию
  useEffect(() => {
    if (subcategories[category]?.length > 0 && !subcategory) {
      setSubcategory(subcategories[category][0]._id);
    }
  }, [subcategories, category]);

  // Устанавливаем метод оплаты по умолчанию
  useEffect(() => {
    setPaymentMethod('TON');
  }, []);

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const updatedFiles = selectedFiles.map((file) => ({
      file,
      name: file.name,
      type: file.type,
      status: 'uploading',
      progress: 0,
    }));
    setFiles((prevFiles) => [...prevFiles, ...updatedFiles]);

    updatedFiles.forEach((file, index) => {
      setTimeout(() => {
        setFiles((prevFiles) =>
          prevFiles.map((f, i) =>
            i === files.length + index ? { ...f, status: 'done', progress: 100 } : f
          )
        );
      }, 2000);
    });
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!telegramId) {
      alert('Ошибка: не удалось получить идентификатор Telegram.');
      return;
    }

    const validPaymentMethods = ['TON', 'USDT(TON)', '$LS'];
    if (!validPaymentMethods.includes(paymentMethod)) {
      alert('Ошибка: выбран неверный метод оплаты.');
      return;
    }

    const orderData = {
      title,
      description,
      acceptablePrice: price,
      paymentMethod,
      customerId: telegramId,
      categoryId: category,
      subcategoryId: subcategory,
    };

    createOrder(orderData, files);
  };

  return (
    <div className='AddProjectWrapper'>
      <div className="AddProject__content">
        <div className="AddProject__tabContent">
          <div className="inputWrapper">
            <Input
              text="Введите заголовок проекта"
              label="Заголовок"
              value={title}
              onChange={setTitle}
            />
          </div>

          <div className="inputWrapper">
            <Textarea
              text="Расскажите про проект максимально подробно"
              label="Описание"
              value={description}
              onChange={setDescription}
            />
          </div>

          <div className="inputWrapper">
            <Select
              items={categories.map(cat => ({ value: cat._id, title: cat.name }))}
              label="Категория"
              value={category}
              onChange={setCategory}
            />
          </div>

          <div className="inputWrapper">
            <Select
              items={(subcategories[category] || []).map(subcat => ({ value: subcat._id, title: subcat.name }))}
              label="Подкатегория"
              value={subcategory}
              onChange={setSubcategory}
            />
          </div>

          <div className="inputWrapper">
            <Select
              items={[
                { value: 'TON', title: 'TON' },
                { value: 'USDT(TON)', title: 'USDT(сеть TON)' },
                { value: '$LS', title: '$LS' }
              ]}
              label="Метод оплаты"
              value={paymentMethod}
              onChange={(value) => setPaymentMethod(value)}
            />
          </div>

          <div className="inputWrapper">
            <Input
              text="Допустимая стоимость работы"
              label="Стоимость"
              value={price}
              onChange={setPrice}
            />
          </div>

          <div className="fileUpload">
            <label htmlFor="fileInput" className="customFileUploadButton">
              <BsPaperclip className="fileIcon" />
              <span>Прикрепить файлы</span>
            </label>
            <input
              id="fileInput"
              type="file"
              multiple
              onChange={handleFileChange}
              className="fileInputHidden"
            />

            <div className="fileList">
              {files.map((file, index) => (
                <div key={index} className={`fileItem ${file.status}`}>
                  <div className="fileIcon">
                    {file.type.includes('image') ? <BsFileRichtext /> : <BsFileEarmarkText />}
                  </div>
                  <div className="fileInfo">
                    <div className="fileName">{file.name}</div>
                    <div className="fileStatus">
                      {file.status === 'uploading' && `${file.progress}%`}
                      {file.status === 'done' && 'Загружено'}
                      {file.status === 'error' && 'Ошибка'}
                    </div>
                  </div>
                  <button className="removeFile" onClick={() => handleRemoveFile(index)}>
                    ✖
                  </button>
                </div>
              ))}
            </div>
          </div>

          <Button text={loading ? "Загрузка..." : "Добавить на биржу"} onClick={handleSubmit} disabled={loading} />
        </div>
      </div>
    </div>
  );
}

export default AddOrderWrapper;
