import React from 'react';
import { BsHouse, BsChat, BsCart, BsBell, BsPerson } from "react-icons/bs";
import { Link } from 'react-router-dom'

function BottomNavigation() {
  const navItems = [
    { href: '/', label: 'Главная', icon: <BsHouse /> },
    { href: '/chats', label: 'Чаты', icon: <BsChat /> },
    { href: '/orders', label: 'Заказы', icon: <BsCart /> },
    { href: '/notifications', label: 'Уведомл.', icon: <BsBell /> },
    { href: '/menu', label: 'Меню', icon: <BsPerson /> }
  ];

  const currentPath = window.location.pathname;

  return (
    <div className='BottomNavigation'>
      <div className="nav__container">
        {navItems.map((item, index) => (
          <Link
            key={index}
            to={item.href} 
            className={`item ${currentPath === item.href ? 'active' : ''}`}
          >
            <div className="iconContainer">
              <div className="icon">
                {item.icon}
              </div>
            </div>

            <div className="text">
              {item.label}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default BottomNavigation;
