import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { api } from '../api/api';
import WebApp from '@twa-dev/sdk';

const useChatStore = create(
    devtools((set, get) => ({
        chats: [],
        currentChat: null,
        messages: [],
        ws: null,

        // Инициализация WebSocket
        initWebSocket: () => {
            const telegramId = WebApp.initDataUnsafe?.user?.id;
            if (!telegramId) {
                console.error('Не удалось получить telegramId из WebApp.');
                return;
            }

            const ws = new WebSocket(`wss://api.loonw-space.com:5000?telegramId=${telegramId}`);

            ws.onopen = () => {
                console.log('[WebSocket] Соединение открыто.');
            };

            ws.onmessage = (event) => {
                const data = JSON.parse(event.data);

                const { currentChat } = get();

                if (data.chatId === currentChat?._id) {
                    set((state) => ({
                        messages: [...state.messages, data], // Добавляем входящее сообщение
                    }));
                } else {
                    console.warn('[WebSocket] Сообщение не для текущего чата:', data.chatId);
                }
            };

            ws.onerror = (error) => {
                console.error('[WebSocket] Ошибка:', error);
            };

            ws.onclose = () => {
                console.warn('[WebSocket] Соединение закрыто. Повторное подключение...');
                setTimeout(() => {
                    get().initWebSocket();
                }, 3000);
            };

            set({ ws });
        },

        // Получение всех чатов пользователя
        fetchChats: async () => {
            const telegramId = WebApp.initDataUnsafe?.user?.id;
            if (!telegramId) {
                console.error('Не удалось получить telegramId из WebApp.');
                return;
            }

            try {
                const response = await api.get(`/chats/${telegramId}`);
                set({ chats: response.data });
            } catch (error) {
                console.error('[fetchChats] Ошибка при загрузке чатов:', error);
            }
        },

        // Получение чата по ID
        fetchChatById: async (chatId) => {
            try {
                const response = await api.get(`/chats/chat/${chatId}`);
                set({
                    currentChat: response.data,
                    messages: response.data.messages,
                });
            } catch (error) {
                console.error('[fetchChatById] Ошибка при загрузке чата:', error);
            }
        },

        // Отправка сообщения
        sendMessage: (chatId, text) => {
            const telegramId = WebApp.initDataUnsafe?.user?.id;
            if (!telegramId) {
                console.error('Не удалось получить telegramId из WebApp.');
                return;
            }

            const { ws, messages } = get();

            // Добавляем сообщение сразу для визуального отклика
            const newMessage = {
                chatId,
                senderTelegramId: telegramId,
                text,
                createdAt: new Date().toISOString(),
            };

            set({ messages: [...messages, newMessage] });

            // Отправляем сообщение через WebSocket
            if (ws && ws.readyState === WebSocket.OPEN) {
                ws.send(JSON.stringify(newMessage));
            } else {
                console.error('[WebSocket] Соединение не открыто.');
            }
        },

        // Добавление нового сообщения (для внешних WebSocket событий)
        addMessage: (message) => {
            const { currentChat } = get();
            if (message.chatId === currentChat?._id) {
                set((state) => ({
                    messages: [...state.messages, message],
                }));
            }
        },

        checkNewMessages: async () => {
            const { currentChat, messages } = get();
        
            // Проверка на существование currentChat и его chatId
            if (!currentChat || !currentChat.chatId) {
                console.error('[checkNewMessages] currentChat или его chatId не определены:', currentChat);
                return;
            }
        
            const lastMessageDate = messages[messages.length - 1]?.createdAt || new Date(0);
        
            try {
                const response = await api.get(`/chats/chat/${currentChat.chatId}/new-messages`, {
                    params: { lastMessageDate },
                });
        
                const newMessages = response.data;
                if (newMessages.length > 0) {
                    set((state) => ({
                        messages: [...state.messages, ...newMessages],
                    }));
                } else {
                }
            } catch (error) {
                console.error('[checkNewMessages] Ошибка при проверке новых сообщений:', error);
            }
        }        
    }))
);

export default useChatStore;
