import React, { useEffect, useState } from 'react';
import useCategoryStore from '../store/categoriesStore';
import useAdvertisingStore from '../store/useAdvertisingStore';
import Input from '../components/ui-kit/Input';
import Textarea from '../components/ui-kit/Textarea';
import Select from '../components/ui-kit/Select';
import Button from '../components/ui-kit/Button';
import WebApp from '@twa-dev/sdk'
import { useNavigate } from 'react-router-dom';

function AddAdvertising() {
  const categories = useCategoryStore((state) => state.categories);
  const subcategories = useCategoryStore((state) => state.subcategories);
  const fetchCategories = useCategoryStore((state) => state.fetchCategories);
  const fetchSubcategoriesByCategoryId = useCategoryStore(
    (state) => state.fetchSubcategoriesByCategoryId
  );

  const { createAdvertisement, loading } = useAdvertisingStore();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [subcategory, setSubcategory] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [price, setPrice] = useState('');
  const [files, setFiles] = useState([]);

  const telegramId = WebApp.initDataUnsafe?.user.id

  const navigate = useNavigate();

  useEffect(() => {
    if (category) {
      fetchSubcategoriesByCategoryId(category); // Загружаем подкатегории при изменении категории
    }
  }, [category, fetchSubcategoriesByCategoryId]);

  const handleFileChange = (e) => {
    setFiles([...e.target.files]);
  };

  console.log(telegramId)

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log({
      title,
      description,
      categoryId: category,
      subcategoryId: subcategory,
      paymentMethod,
      price,
      telegramId,
    });
    if (!paymentMethod) {
      console.error('Метод оплаты не выбран!');
      return;
    }
    await createAdvertisement(
      {
        title,
        description,
        categoryId: category,
        subcategoryId: subcategory,
        paymentMethod,
        price,
        telegramId,
      },
      files
    );
  };  

  useEffect(() => {
    const backButton = WebApp.BackButton;

    backButton.show();
    backButton.onClick(() => {
        console.log('[TWA BackButton] Возврат назад');
        navigate('/menu'); // Переход на предыдущую страницу
    });

    return () => {
        backButton.hide();
        backButton.offClick();
    };
  }, [navigate]);

  useEffect(() => {
    fetchCategories(); // Загружаем категории при монтировании компонента
  }, [fetchCategories]);

  return (
    <div className="AddAdvertisingWrapper">
      <div className="AddAdvertising__container">
        <div className="AddAdvertising__tabContent">
          <div className="inputWrapper">
            <Input
              label="Заголовок"
              text="Введите заголовок"
              value={title}
              onChange={setTitle}
            />
          </div>

          <div className="inputWrapper">
            <Textarea
              label="Описание"
              text="Расскажите про свои услуги максимально подробно"
              value={description}
              onChange={setDescription}
            />
          </div>

          <div className="inputWrapper">
            <Select
              label="Категория"
              items={categories.map((cat) => ({
                value: cat._id,
                title: cat.name,
              }))}
              value={category}
              onChange={setCategory}
            />
          </div>

          <div className="inputWrapper">
            <Select
              label="Подкатегория"
              items={(subcategories[category] || []).map((subcat) => ({
                value: subcat._id,
                title: subcat.name,
              }))}
              value={subcategory}
              onChange={setSubcategory}
              disabled={!category}
            />
          </div>

          <div className="inputWrapper">
            <Select
              label="Метод оплаты"
              items={[
                { value: 'TON', title: 'TON' },
                { value: 'USDT(TON)', title: 'USDT(сеть TON)' },
                { value: '$LS', title: '$LS' },
              ]}
              value={paymentMethod}
              onChange={(value) => {
                console.log('Выбран метод оплаты:', value);
                setPaymentMethod(value);
              }}
            />
          </div>

          <div className="inputWrapper">
            <Input
              label="Стоимость"
              text="Средняя стоимость работы"
              value={price}
              onChange={setPrice}
            />
          </div>

{/*           <div className="fileUpload inputWrapper">
            <label htmlFor="fileInput" className="customFileUploadButton">
              <span>Добавить обложку</span>
            </label>
            <input
              id="fileInput"
              type="file"
              multiple
              onChange={handleFileChange}
              className="fileInputHidden"
            />

            <div className="fileList">
              {files.map((file, index) => (
                <div key={index} className="fileItem">
                  <span>{file.name}</span>
                </div>
              ))}
            </div>
          </div> */}

          <Button
            text={loading ? 'Загрузка...' : 'Добавить объявление'}
            onClick={handleSubmit}
            disabled={loading}
          />
        </div>
      </div>
    </div>
  );
}

export default AddAdvertising;
