import React from 'react'
import PortfolioBlock from '../Portfolio/PortfolioBlock'

function PortfolioProfile({portfolio}) {
  return (
    <div className='PortfolioProfile'>
        <div className="title">Портфолио</div>

        {portfolio.map((item, index) => (
            <PortfolioBlock
                key={index}
                img={`https://api.loonw-space.com:5000${item.file}`} 
                info={item.description} 
                widthLeft="100%" 
                showButton={false}
            />
        ))}
    </div>
  )
}

export default PortfolioProfile