import React from 'react'
import ReviewsBlock from './ReviewsBlock'
import EmptyData from '../EmptyData'

function ReviewsProfile() {
  return (
    <div className='ReviewsProfile'>
        <div className="title">
            Отзывы:
        </div>

        <EmptyData />
    </div>
  )
}

export default ReviewsProfile