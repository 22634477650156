import React, { useEffect } from 'react';
import AdvertisingBlock from '../components/Advertising/AdvertisingBlock';
import EmptyData from '../components/EmptyData'; // Импорт компонента
import { useNavigate, useParams } from 'react-router-dom';
import useAdvertisingStore from '../store/useAdvertisingStore';
import useCategoryStore from '../store/categoriesStore';
import WebApp from '@twa-dev/sdk';

function Advertising() {
  const { advertisements, fetchFilteredAdvertisements, loading } = useAdvertisingStore();
  const { categories, fetchCategories } = useCategoryStore();
  const { subCategoryId, categoryId } = useParams();

  const category = categories.find((cat) => cat._id === categoryId);
  const subcategory = category?.subcategories?.find((sub) => sub._id === subCategoryId);

  useEffect(() => {
    if (categories.length === 0) {
      fetchCategories();
    }
    fetchFilteredAdvertisements(categoryId, subCategoryId);
  }, [fetchFilteredAdvertisements, fetchCategories, categories, categoryId, subCategoryId]);

  const navigate = useNavigate();

  useEffect(() => {
      const backButton = WebApp.BackButton;
  
      backButton.show();
      backButton.onClick(() => {
          console.log('[TWA BackButton] Возврат назад');
          navigate('/');
      });
  
      return () => {
          backButton.hide();
          backButton.offClick();
      };
  }, [navigate]);

  return (
    <div className='Advertising'>
      <div className="Advertising__container">
        <div className="titlePage">Предложения исполнителей</div>
        <div className="descPage">
          {subcategory
            ? `В подкатегории: ${subcategory.name}`
            : category
              ? `В категории: ${category.name}`
              : 'Все категории'}
        </div>

        {loading ? (
          <div>Загрузка...</div>
        ) : advertisements.length === 0 ? (
          <EmptyData /> // Компонент, если данных нет
        ) : (
          advertisements.map((ad) => (
            <AdvertisingBlock
              key={ad._id}
              author={ad.authorId}
              title={ad.title}
              description={ad.description}
              paymentMethod={ad.paymentMethod}
              price={ad.price}
              showButton={true}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default Advertising;
