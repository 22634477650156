import React from 'react';
import { BsCheckCircle, BsArrowRightCircle } from 'react-icons/bs';
import useTaskStore from '../../store/taskStore';
import WebApp from '@twa-dev/sdk';

function TaskBlock({ title, reward, taskId, goTo }) {
  const { checkTask } = useTaskStore();
  const userId = WebApp.initDataUnsafe?.user.id;

  const handleCheckTask = () => {
    checkTask(userId, taskId);
  };

  const handleOpenLink = () => {
    if (goTo) {
      window.open(goTo, '_blank');
    }
  };

  return (
    <div className="TaskBlock">
      <div className="left">
        <div className="title">{title}</div>
        <div className="text">
          Награда: <span>{reward} $LS</span>
        </div>
      </div>

      <div className="rightBlock">
        <div className="right" onClick={handleCheckTask} style={{ cursor: 'pointer' }}>
          <BsCheckCircle />
        </div>

        {goTo && (
          <div className="right" onClick={handleOpenLink} style={{ cursor: 'pointer' }}>
            <BsArrowRightCircle />
          </div>
        )}
      </div>
    </div>
  );
}

export default TaskBlock;
