import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { api } from '../api/api';
import { showNotification } from '../ToastNotification';

const useTaskStore = create(
  devtools((set, get) => ({
    tasks: [],
    completedTaskIds: [],
    isLoading: false,
    error: null,
    successMessage: null,

    fetchTasks: async () => {
      set({ isLoading: true, error: null });
      try {
        const response = await api.get('/tasks');
        set({ tasks: response.data });
      } catch (error) {
        showNotification('Ошибка при получении списка заданий', 'error');
        set({ error: 'Ошибка при получении заданий' });
      } finally {
        set({ isLoading: false });
      }
    },

    checkTask: async (userId, taskId) => {
      try {
        const response = await api.post('/tasks/check', { userId, taskId });
        set((state) => ({
          successMessage: response.data.message,
          completedTaskIds: [...state.completedTaskIds, taskId],
        }));
        showNotification('Задание выполнено успешно!', 'success');
        await get().fetchTasks();
      } catch (error) {
        showNotification(
          error.response?.data?.error || 'Ошибка при выполнении задания',
          'error'
        );
        set({ error: error.response?.data?.error || 'Ошибка выполнения задания' });
      }
    },
  }))
);

export default useTaskStore;
