import React, { useEffect } from 'react'
import BalanceHeader from '../components/Balance/BalanceHeader'
import { useNavigate } from 'react-router-dom';
import WebApp from '@twa-dev/sdk';

function Balance() {
    const navigate = useNavigate();

    useEffect(() => {
      const backButton = WebApp.BackButton;
  
      backButton.show();
      backButton.onClick(() => {
          console.log('[TWA BackButton] Возврат назад');
          navigate('/menu');
      });
  
      return () => {
          backButton.hide();
          backButton.offClick();
      };
    }, [navigate]);

    return (
        <div className='Balance'>
            <div className="Balance__container">
                <div className="titlePage">
                    Баланс
                </div>

                <BalanceHeader />

                <div className="text">
                    История транзакций пуста
                </div>
            </div>
        </div>
    )
}

export default Balance