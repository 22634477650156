import React, { useEffect } from 'react'
import AddOrderWrapper from '../components/AddOrder/AddOrderWrapper'
import { useNavigate } from 'react-router-dom';
import WebApp from '@twa-dev/sdk';

function AddOrder() {
  const navigate = useNavigate();

  useEffect(() => {
    const backButton = WebApp.BackButton;

    backButton.show();
    backButton.onClick(() => {
        console.log('[TWA BackButton] Возврат назад');
        navigate('/menu'); // Возвращаемся на страницу чатов
    });

    return () => {
        backButton.hide();
        backButton.offClick();
    };
  }, [navigate]);

  return (
    <div className='AddProject'>
        <div className="AddProject__container">
            <div className="titlePage">
                Добавить проект
            </div>

            <AddOrderWrapper />
        </div>
    </div>
  )
}

export default AddOrder