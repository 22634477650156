import React, { useEffect, useState } from 'react';
import Input from '../ui-kit/Input';
import Select from '../ui-kit/Select';
import Textarea from '../ui-kit/Textarea';
import Button from '../ui-kit/Button';
import WebApp from '@twa-dev/sdk';
import OrderBlock from '../Orders/OrderBlock';
import useOrderStore from '../../store/orderStore';

function AddResponseWrapper({ orderId }) {
  const { fetchOrderDetails, addResponseToOrder, orders, loading } = useOrderStore();
  const user = WebApp.initDataUnsafe?.user;

  const [price, setPrice] = useState('');
  const [duration, setDuration] = useState(''); // Срок выполнения
  const [description, setDescription] = useState('');

  const durationOptions = [
    { value: '1d', title: '1 день' },
    { value: '2d', title: '2 дня' },
    { value: '3d', title: '3 дня' },
    { value: '1w', title: '1 неделя' },
    { value: '2w', title: '2 недели' },
  ];

  useEffect(() => {
    fetchOrderDetails(orderId);
  }, [orderId, fetchOrderDetails]);

  useEffect(() => {
    // Устанавливаем первый элемент списка как дефолтный
    if (durationOptions.length > 0) {
      setDuration(durationOptions[0].value);
    }
  }, []);

  const currentOrder = orders.find((order) => order._id === orderId);

  const handleResponseSubmit = (e) => {
    e.preventDefault();
    addResponseToOrder({
      orderId,
      telegramId: user.id,
      price,
      duration,
      description,
    });
  };

  return (
    <div className="ResponseWrapper">
      <div className="ResponseWrapper__container">
        <div className="titlePage">Отклик на проект</div>

        {loading ? (
          <div>Загрузка...</div>
        ) : currentOrder ? (
          <OrderBlock
            author={{
              name: currentOrder.customerId.nickname,
              avatar: currentOrder.customerId.avatar,
            }}
            title={currentOrder.title}
            desc={currentOrder.description}
            price={currentOrder.acceptablePrice}
            responses={`Откликов: ${currentOrder.responses?.length || 0}`}
            files={currentOrder.files}
            showButton={false}
          />
        ) : (
          <div>Заказ не найден</div>
        )}

        {/* Форма отклика */}
        <div className="inputWrapper">
          <Input
            text="Ваше предложение по цене"
            label="Стоимость"
            value={price}
            onChange={setPrice}
          />
        </div>

        <div className="inputWrapper">
          <Select
            items={durationOptions}
            label="Срок"
            value={duration}
            onChange={setDuration}
          />
        </div>

        <div className="inputWrapper">
          <Textarea
            text="Ваши вопросы или детали"
            label="Описание"
            value={description}
            onChange={setDescription}
          />
        </div>

        <Button
          text="Откликнуться"
          onClick={handleResponseSubmit}
          disabled={!price || !duration || !description}
        />
      </div>
    </div>
  );
}

export default AddResponseWrapper;
