import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useChatStore from '../../store/chatStore';
import WebApp from '@twa-dev/sdk';
import DefaultAvatar from '../../assets/defaultAvatar.png';
import EmptyData from '../EmptyData';

function ChatList() {
  const { chats, fetchChats, fetchChatById } = useChatStore();
  const navigate = useNavigate();

  const currentUser = WebApp.initDataUnsafe?.user?.id;

  useEffect(() => {
    fetchChats();
  }, [fetchChats]);

  const handleChatClick = async (chatId) => {
    await fetchChatById(chatId);
    navigate(`/chat/${chatId}`);
  };

  return (
    <div className="ChatList">
      {chats.length === 0 ? (
        <EmptyData />
      ) : (
        chats.map((chat) => {
          const opponent = chat.participants.find((p) => String(p.telegramId) !== String(currentUser));

          return (
            <div key={chat._id} onClick={() => handleChatClick(chat._id)} className="chat">
              <img
                src={opponent?.avatar ? `https://api.loonw-space.com:5000${opponent.avatar}` : DefaultAvatar}
                alt="Avatar"
                className="avatar"
              />
              <div className="nickname">{opponent?.nickname || 'Собеседник'}</div>
            </div>
          );
        })
      )}
    </div>
  );
}

export default ChatList;
