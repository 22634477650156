import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone'; 
import { FaTrashAlt } from 'react-icons/fa';
import Input from '../components/ui-kit/Input';
import Button from '../components/ui-kit/Button';
import usePortfolioStore from '../store/portfolioStore';  
import { showNotification } from '../ToastNotification';
import WebApp from '@twa-dev/sdk'
import { useNavigate } from 'react-router-dom';

function AddJobInPortfolio() {
  const [file, setFile] = useState(null);
  const [description, setDescription] = useState(''); 
  const { addWorkToPortfolio, isLoading, error } = usePortfolioStore();

  const telegramId = WebApp.initDataUnsafe?.user.id;

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.jpg,.jpeg,.png',
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles[0]);
    },
  });

  const handleDelete = () => {
    setFile(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      showNotification('Загрузите изображение', 'error'); 
      return;
    }

    await addWorkToPortfolio(telegramId, file, description);
    setFile(null); 
    setDescription(''); 
  };

  const handleDescriptionChange = (value) => {
    setDescription(value);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const backButton = WebApp.BackButton;

    backButton.show();
    backButton.onClick(() => {
        console.log('[TWA BackButton] Возврат назад');
        navigate('/portfolio');
    });

    return () => {
        backButton.hide();
        backButton.offClick();
    };
  }, [navigate]);

  return (
    <div className='AddJobInPortfolio'>
      <div className="AddJobInPortfolio__container">
        <div className="titlePage">
          Добавить работу:
        </div>

        {file ? (
          <div className="uploadedImage">
            <img src={URL.createObjectURL(file)} alt="Uploaded" />
            <div className="deleteIcon" onClick={handleDelete}>
              <FaTrashAlt size={24} color="red" />
            </div>
          </div>
        ) : (
          <div className="dropzone" {...getRootProps()}>
            <input {...getInputProps()} />
            <p>Перетащите файл сюда или нажмите для выбора файла</p>
          </div>
        )}

        <Input
          text="Краткое описание работы"
          value={description}
          onChange={handleDescriptionChange}
        />

        <div className="buttonBlock">
          <Button text='Добавить' onClick={handleSubmit} />
        </div>

        {isLoading && <p>Загрузка...</p>}
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </div>
    </div>
  );
}

export default AddJobInPortfolio;
