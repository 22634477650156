import React from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import usePortfolioStore from '../../store/portfolioStore';

function PortfolioBlock(props) {
  const { deleteWorkFromPortfolio } = usePortfolioStore();

  const handleDelete = () => {
    deleteWorkFromPortfolio(props.id);
  };

  return (
    <div className='PortfolioBlock'>
        <div className="portfilioImage">
            <Zoom>
                <img src={props.img} alt="" />
            </Zoom>
        </div>

        <div className="portfolio__info">
            <div className="left" style={{width: props.widthLeft}}>
                {props.info}
            </div>

            {props.showButton && (
                <div onClick={handleDelete} className="right">
                    <a href="#">Удалить</a>
                </div>
            )}
        </div>
    </div>
  );
}

export default PortfolioBlock;
